.logo-container {
  z-index: 0;
  width: 400px;
  height: 550px;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  opacity: 1;

  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateZ(30deg) !important;
  }

  .solid-logo {
    position: absolute;
    top: -180%;
    right: auto;
    bottom: auto;
    left: -370%;
    margin: auto;
    width: 800%;
    opacity: 0.08;
    //transform: rotateZ(30deg) !important;
    z-index: 1;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10px;
}
